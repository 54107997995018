//this component will update details of daily
// progress report.

import React from 'react';

const UpdateForm = () => {
    return (
        <div>
            Update Form (Coming soon)
        </div>
    );
};

export default UpdateForm;