import React from 'react';

const serverURL = process.env.REACT_APP_SERVER_URL;

const URL = () => {
    return (
        <div>
            
        </div>
    );
};

export {serverURL};

export default URL;